import * as React from "react"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import ViewportBlock from "./viewportBlock"

// styles
import * as styles from "../css/header.module.css"


const HeaderSection = ({ backgroundImage, children }) => {

  const image = getImage(backgroundImage)
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage className={styles.header} {...bgImage}>
      <div className="container">
        <ViewportBlock className={styles.sectionHead + ' reveal'}>
          <h3 className={styles.sectionTitle}>{children}</h3>
        </ViewportBlock>
      </div>
    </BackgroundImage>
  )
}

export default HeaderSection
