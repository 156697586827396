import * as React from "react"
import ContactusSection from "../components/mainpage/ContactusSection"
import FooterSection from "../components/mainpage/FooterSection"
import HeaderSection from "../components/HeaderSection"
import Menuzord from "../components/mainpage/Menuzord"
import { graphql } from "gatsby"
import ViewportBlock from "../components/viewportBlock"
// styles
import * as styles from "../css/about.module.css"
import Seo from "../components/seo"


const AboutPage = ({ data }) => {
  const { imageFile } = data.directus_system.files[0];
  return (
    <>
      <Seo title="О нас"/>
      <Menuzord pageName="about" />
      <HeaderSection backgroundImage={imageFile}>С нами стоит сотрудничать</HeaderSection>
      <section className={styles.blogSection}>
        <div className="container">
          <ViewportBlock className={styles.blogSectionHead + ' reveal'}>
            <h1>Почему выбирают нас?</h1>
          </ViewportBlock>
          <div className={styles.row}>
            <div className={styles.blogSectionContent}>
              <ViewportBlock component="h3" className="reveal">Конкурентные преимущества</ViewportBlock>
              <hr></hr>
              <ViewportBlock component="ul" className={styles.blogSectionUl + ' reveal'}>
                <li>ТОО “БатысГеофизСервис” имеет гос. лицензию на проведение всех видов геофизических работ, лицензию на обращение с источниками ионизирующего излучения, а также все разрешительные документы на право производтсва работ и перевозку всех взрывчатых и радиоционных веществ</li>
                <li>с 2005 БГС является членом междунарожной ассоциации научно-технического и делового сотрудничества по геофизическим исследованиям и работам в скважинах</li>
                <li>Компания и сотрудники сертифицированны по программе системы менеджмента качества в соответствии с требованиями ISO-9001-2001</li>
                <li>Производственная база БГС оснащена современной аппартурой, оборудованием, различным ПО отвечающим требованиям действующих Казахстанских стандартов качества</li>
                <li>За период с 2004 по 2014 приобретены и введены в эксплуатацию 21 каротажный подъемник Российского и Американского производства. На базе этих подъемников сформированны совмещенные комплексные, оснащённые современной компьютерной каротажной станцией КЕДР, Гектор и Excell 2000</li>
                <li>Производственная база включает в себя геофизическую лабораторию, бокс для ремонта и содержания транспортных средств, базисный склад взрывчатых материалов, хранилище источников ионизирующего излучения, диспетчерскую службу, производственно-технический отдел, ремонтно-механическую мастерскую</li>
                <li>Обработка материалов ГИС производится контрольно-интепретационной партией с использованием современных технологий, программного обеспечения PRIME-SOLVER</li>
                <li>За весь период деятельности компания добилась широкого спектра географии работ, четкой координации финансовых и технических ресурсов, увеличивая объемы выполненных работ.</li>
                <li>В число наших заказчиков входит АО УзеньМунайГаз, АО КаражанбасМунай, ТОО MunaiFieldService, ТОО СП КазГерМунай</li>
              </ViewportBlock>
              <br></br>
              <br></br>
              <br></br>
              <ViewportBlock component="h3" className="reveal">Социальная ответственность</ViewportBlock>
              <hr></hr>
              <ViewportBlock component="p" className={styles.blogSectionP + ' reveal'}>Главной ценностью компании являются сотрудники, создание хороших условий труда, развитие персонала, приоритет социальной и кадровой политики предприятий. Важным преимуществом БГС является привлечение исключительно Казахстанских специалистов и вспомогательного персонала тех регионов где проводятся работы.</ViewportBlock>
              <ViewportBlock component="p" className="reveal">В течении 10 лет обеспечила постоянной работой более 150 человек практически со всех регионов Казахстана, многие из которых выпускники известных не только Казахстанских, но и ВУЗов зарубежья</ViewportBlock>
              <br></br>
              <br></br>
              <br></br>
              <ViewportBlock component="h3" className="reveal">Перечень предоставляемых услуг</ViewportBlock>
              <hr></hr>
              <ViewportBlock component="ul" className={styles.blogSectionUl + ' reveal'}>
                <li>Работы в открытом стволе. Промежуточные и заключительные каротажи.</li>
                <li>Перфорация нефтяных и газовых скважин, проводимая на кабеле и НКТ.</li>
                <li>Контроль за разработкой нефтяных и газовых скважин:

                  <ol className={styles.blogSectionOl}>
                    <li>Определение профиля приёмистости скважин.</li>
                    <li>Определение профиля притока скважины.</li>
                    <li>Определение герметичности скважины.</li>
                    <li>Определение текущей нефтенасыщенности приборами импульсного нейтронного каротажа.</li>
                  </ol>
                </li>
                <li>Техническое состояние скважин:

                  <ol className={styles.blogSectionOl}>
                    <li>Определение качества сцепления цемента с породой и колонной приборами акустического каротажа.</li>
                    <li>Определение качества цементирования приборами радиоактивного каротажа.</li>
                    <li>Определение состояния колонны приборами электромагнитной дефектоскопии.</li>
                  </ol>
                </li>
              </ViewportBlock>
            </div>
          </div>
        </div>
      </section>
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default AboutPage

export const query = graphql`
  query HeaderAbout {
    directus_system {
      files(filter: {title: {_eq: "About Background"}}) {
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        id
      }
    }
  }
`